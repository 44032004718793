/* eslint-disable no-undef */
import Cookies from "js-cookie";

$(document).ready(function() {
  // Get recaptcha cookie, if it doesn't exist don't execute this
  const recaptchaSiteKey = Cookies.get("recaptcha");
  if (recaptchaSiteKey === undefined) return;

  // Load recaptcha script
  $.getScript(
    `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`
  ).done(function() {
    grecaptcha.ready(function() {
      // Hide recaptcha badge
      $(".grecaptcha-badge").css("visibility", "collapse");

      // Fix for reserved namespace on buttons
      $("input[name='submit'], button[name='submit']").attr(
        "name",
        "submt_form"
      );

      // On form submit
      function recaptchaExecution(evt) {
        evt.preventDefault();
        const form = this;
        // If not post don't get recaptcha
        if (
          $(form)
            .attr("method")
            .toUpperCase() !== "POST"
        ) {
          $(form)
            .off("submit", recaptchaExecution)
            .submit();
        }
        // Get recaptcha token and add to form before submitting
        grecaptcha.execute(recaptchaSiteKey).then(function(token) {
          $("<input />")
            .attr({
              name: "recaptcha",
              type: "hidden",
              value: token
            })
            .appendTo(form);
          $(form)
            .off("submit", recaptchaExecution)
            .submit();
        });
      }

      $("form").on("submit", recaptchaExecution);
    });
  });
});
